import React, { useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { ClientCarouselData } from "../data"
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper"

export default function ClientCarousel() {
  return (
    <>
      <div className="brand-carousel-wrapper">
        <div className="thm-container-carousel">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            slidesPerView={6}
            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            // pagination={{
            //   clickable: true,
            // }}
            // navigation={true}
            modules={[Autoplay]}
            className="brand-carousel"
          >
            {ClientCarouselData.map(({ image }, index) => (
              <SwiperSlide key={index}>
                <div className="item">
                  <img src={image} alt="Awesome Image" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}
