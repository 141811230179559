import React from "react"
import ClientCarousel from "../components/client-carousel"
import Footer from "../components/footer"
import HeaderOne from "../components/header-one"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import ServiceTabOne from "../components/service-tab-1"
import MenuContextProvider from "../context/menu-context"

const Services = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Service">
        <HeaderOne />
        <PageHeader title="Services" name="Services" />
        <ServiceTabOne />
        <ClientCarousel />
        <Footer />
      </Layout>
    </MenuContextProvider>
  )
}

export default Services
